var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Toolbar',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"right",fn:function(){return [_vm._v(" Sicht ")]},proxy:true},{key:"left",fn:function(){return [_c('Button',{staticClass:"p-button-danger",attrs:{"icon":"pi pi-times"},on:{"click":function($event){return _vm.$router.push('/admin/clients')}}})]},proxy:true}])}),_c('div',{staticClass:"form-card row"},[_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"mb-3"},[_vm._m(0),_c('b-form-select',{staticClass:"mb-3",model:{value:(_vm.body.sexType),callback:function ($$v) {_vm.$set(_vm.body, "sexType", $$v)},expression:"body.sexType"}},[_c('b-form-select-option',{attrs:{"value":"MR"}},[_vm._v("MR ")]),_c('b-form-select-option',{attrs:{"value":"MRS"}},[_vm._v("MRS")])],1)],1)]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"mb-3"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.fname),expression:"body.fname"}],staticClass:"form-control",attrs:{"type":"text","id":"fname"},domProps:{"value":(_vm.body.fname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "fname", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"mb-3"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.lname),expression:"body.lname"}],staticClass:"form-control",attrs:{"type":"text","id":"lname"},domProps:{"value":(_vm.body.lname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "lname", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_vm._m(3),_c('vue-tel-input',_vm._b({attrs:{"allCountries":_vm.allCountries},model:{value:(_vm.body.phone),callback:function ($$v) {_vm.$set(_vm.body, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"body.phone"}},'vue-tel-input',{
            mode: 'international',
          },false))],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"email"}},[_vm._v("Email address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.email),expression:"body.email"}],staticClass:"form-control",attrs:{"type":"email","id":"email","placeholder":"name@example.com"},domProps:{"value":(_vm.body.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "email", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"password"}},[_vm._v(" password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.body.password),expression:"body.password"}],staticClass:"form-control",attrs:{"type":"password","id":"password"},domProps:{"value":(_vm.body.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.body, "password", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"notes"}},[_vm._v(" Notes ")]),_c('Textarea',{attrs:{"cols":"30"},model:{value:(_vm.body.notes),callback:function ($$v) {_vm.$set(_vm.body, "notes", $$v)},expression:"body.notes"}})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"mb-3 text-center"},[(_vm.id)?_c('Button',{staticClass:"ml-2 p-button-info",attrs:{"label":"Bearbeiten","icon":"pi pi-pencil","disabled":_vm.disabledAdd},on:{"click":function($event){return _vm.update()}}}):_c('Button',{staticClass:"ml-2 p-button-success",attrs:{"label":"Hinzufügen","icon":"pi pi-plus","disabled":_vm.disabledAdd},on:{"click":function($event){return _vm.save()}}})],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"sexType"}},[_vm._v(" Typ"),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"fname"}},[_vm._v(" Vorname "),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"lname"}},[_vm._v("Last Name "),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-label",attrs:{"for":"phone"}},[_vm._v("Phone "),_c('b',{staticStyle:{"color":"red"}},[_vm._v("*")])])
}]

export { render, staticRenderFns }