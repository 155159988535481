<template>
  <div>
    <Toolbar class="mb-4">
      <template #right> Sicht </template>
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/clients')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-2">
        <div class="mb-3">
          <label for="sexType" class="form-label">
           Typ<b style="color: red">*</b>
          </label>

          <b-form-select v-model="body.sexType" class="mb-3">
            <b-form-select-option value="MR">MR </b-form-select-option>
            <b-form-select-option value="MRS">MRS</b-form-select-option>
          </b-form-select>
        </div>
      </div>
      <div class="col-md-5">
        <div class="mb-3">
          <label for="fname" class="form-label">
           Vorname <b style="color: red">*</b></label
          >
          <input
            type="text"
            class="form-control"
            id="fname"
            v-model="body.fname"
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="mb-3">
          <label for="lname" class="form-label"
            >Last Name <b style="color: red">*</b></label
          >
          <input
            type="text"
            class="form-control"
            id="lname"
            v-model="body.lname"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label"
            >Phone <b style="color: red">*</b></label
          >

          <vue-tel-input
            v-model.trim="body.phone"
            v-bind="{
              mode: 'international',
            }"
            :allCountries="allCountries"
          ></vue-tel-input>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="body.email"
            placeholder="name@example.com"
          />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="password" class="form-label"> password </label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="body.password"
          />
        </div>
      </div>

      <!-- <div class="col-md-6">
        <div class="mb-3">
          <label for="country" class="form-label">Land</label>
          <input
            type="text"
            class="form-control"
            id="country"
            v-model="body.country"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="city" class="form-label"> Stadt </label>
          <input
            type="text"
            class="form-control"
            id="city"
            v-model="body.city"
          />
        </div>
      </div>

      <div class="col-md-10">
        <div class="mb-3">
          <label for="address" class="form-label"> Address Home </label>
          <input
            type="text"
            class="form-control"
            id="address"
            v-model="body.address"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="mb-3">
          <label for="zip" class="form-label"> PLZ </label>
          <input type="text" class="form-control" id="zip" v-model="body.zip" />
        </div>
      </div> -->

      <div class="col-md-12">
        <div class="mb-3">
          <label for="notes" class="form-label"> Notes </label>
          <Textarea v-model="body.notes"  cols="30" />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button
            label="Bearbeiten"
            icon="pi pi-pencil"
            class="ml-2 p-button-info"
            @click="update()"
            v-if="id"
            :disabled="disabledAdd"
          />
          <Button
            v-else
            label="Hinzufügen"
            icon="pi pi-plus"
            class="ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        sexType: null,
        fname: null,
        lname: null,
        phone: null,
        password: null,

        cpassword: null,
        email: null,
        address: null,
        zip: null,

        country: null,
        city: null,
      },
      id: null,

      isInvalid: false,
      disabledAdd: false,
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),
    };
  },
  methods: {
    save() {
      if (
        this.body.sexType &&
        this.body.fname &&
        this.body.lname &&
        this.body.phone
      ) {
        this.disabledAdd = true;
        this.$http.post(`users`, this.body).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push("/admin/clients");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.fname && this.body.lname && this.body.phone) {
        this.disabledAdd = true;

        this.$http.put(`users/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push("/admin/clients");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`users/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    }
  },
};
</script>

<style></style>

<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
